import { useContext, useEffect, useState } from "react";
import UserContext from "../context/userContext";
import { Link } from "react-router-dom";

const Cart = () => {
    const [cart, setCart] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { user } = useContext(UserContext);

    const calcTotal = () => {
        let total = 0;
        cart.forEach((item) => {
            total += item.product_price * item.qty;
        });
        return total.toFixed(2);
    };

    const countItems = () => {
        let count = 0;
        cart.forEach((item) => {
            count += parseInt(item.qty);
        });
        return count;
    };

    const updateItemQty = async (id, qty) => {
        let newCart = cart;
        let idx = newCart.findIndex((item) => item.product_id === id)

        if (id) {
            console.log(`Changed item ${id} qty: ${newCart[idx].qty} -> ${qty}`);
            newCart[idx].qty = qty;
        }

        const body = {
            cart: newCart,
        };

        await fetch(`${process.env.REACT_APP_API_URL}/cart/${user}`, {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(body),
        });
        setIsLoading(true)
    }

    const removeFromCart = async (id) => {
        let newCart = [];
        if (id) {
            newCart = cart.filter((item) => item.product_id !== id);
        }

        console.log(`Remove ${id} from cart`);

        const body = {
            cart: newCart,
        };

        await fetch(`${process.env.REACT_APP_API_URL}/cart/${user}`, {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(body),
        });
        setIsLoading(true);
    };

    useEffect(() => {
        const getUserCart = async () => {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/cart/${user}`);
            const userCart = await res.json();

            // console.log("cart", cart);

            setCart(userCart);
            setIsLoading(false);
        };

        if (user) {
            getUserCart(user);
        } else {
            setIsLoading(false);
        }
    }, [user, isLoading]);

    if (isLoading) {
        return <></>;
    }

    if (!user) {
        return (
            <div className="Cart d-flex flex-column justify-content-center col-md-6 col-10 mx-auto my-auto">
                <div className="row">
                    <h1 className="display-4 mb-4">Your Marafyde cart is empty.</h1>
                    <h2 className="lead fs-4">Please log in to start adding items to your cart</h2>
                </div>
                <div className="row justify-content-center mt-3">
                    <Link className="btn btn-lg btn-primary col-xl-3 col-md-5 col-5 my-1 px-2 mx-3" to="/login">
                        Log in
                    </Link>
                    <Link
                        className="btn btn-lg btn-outline-primary col-xl-3 col-md-5 col-5 my-1 px-2 mx-3"
                        to="/signup"
                    >
                        Sign up now
                    </Link>
                </div>
            </div>
        );
    }

    if (cart.length === 0) {
        return (
            <div className="Cart my-5">
                <div className="col-12 col-lg-11 col-xl-10 d-flex mx-auto" style={{ minWidth: "860px" }}>
                    <div className="col-8 d-flex flex-column ms-3 py-3 border border-dark rounded align-items">
                        <div className="col-11 mx-auto">
                            <h1 className="text-start display-6 my-3">Shopping Cart</h1>
                            <hr className="mx-auto mb-3" />
                            <h1 className="fs-3 text-start fw-bold mt-5 mb-3">Your Marafyde cart is empty.</h1>
                            <h1 className="lead fs-5 text-start mt-5 mb-3">
                                Click one of the links below to start adding items to your cart.
                            </h1>
                            <div className="col-12 d-flex justify-content-start mt-2">
                                <Link className="btn btn-primary" to="/products">
                                    Browse All Products
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="Cart my-5">
            <div
                className="col-12 col-lg-11 col-xl-10 d-flex justify-content-between mx-auto"
                style={{ minWidth: "860px" }}
            >
                <div className="col-8 d-flex flex-column justify-content-center ms-3 pt-3 border border-dark rounded">
                    <div className="col-11 mx-auto">
                        <h1 className="text-start display-6 my-3">Shopping Cart</h1>
                        <hr className="mx-auto mb-3" />
                    </div>
                    {cart.map((item) => (
                        <div className="" key={item.product_id}>
                            <div
                                key={item.product_id}
                                className="col-11 mx-auto mt-2 mb-4 py-1 d-flex flex-row"
                                style={{ height: "10rem" }}
                            >
                                {/* Product Image */}
                                <Link
                                    to={`/products/${item.product_id}`}
                                    className="col-4 col-lg-3 d-flex justify-content-start"
                                >
                                    <img style={{ height: "10rem" }} src={item.image} alt="" className="img-fluid" />
                                </Link>
                                {/* Product Image */}

                                {/* Product Name + Buttons */}
                                <div className="col-lg-6 col-6 d-flex flex-column justify-content-between text-start">
                                    <div>
                                        <Link to={`/products/${item.product_id}`}>
                                            <p className="text-dark fs-4 lead mb-1" style={{ fontWeight: "400" }}>
                                                {item.product_name}
                                            </p>
                                        </Link>
                                    </div>
                                    <div className="d-flex gap-3">
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-light border-secondary dropdown-toggle"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Qty: {item.qty}
                                            </button>
                                            <ul className="dropdown-menu qty-btn-dropdown">
                                                {[1,2,3,4,5,6,7,8,9,10].map((q) => (
                                                    <li className="dropdown-item qty-btn-item text-center" onClick={() => updateItemQty(item.product_id, q)} key={q}>
                                                        {q}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="vr ms-2"></div>
                                        <button
                                            onClick={() => removeFromCart(item.product_id)}
                                            className="btn btn-danger text-danger bg-white cart-delete-btn text-start fs-6"
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                                {/* Product Name + Buttons */}

                                {/* Product Price */}
                                <div className="col-2 col-lg-3 fs-4 text-dark text-end fw-bold pe-1">
                                    ${(item.product_price * item.qty).toFixed(2)}
                                </div>
                                {/* Product Price */}
                            </div>
                            <hr className="col-11 mx-auto mt-3 mb-4" />
                        </div>
                    ))}
                    <p className="col-11 text-end mx-auto mt-2">
                        Subtotal ({countItems()} items): <span className="fw-bold">${calcTotal()}</span>
                    </p>
                </div>
                <div
                    className="col-3 d-flex flex-column justify-content-between border border-dark rounded me-3"
                    style={{ height: "10rem" }}
                >
                    <p className="col-10 text-start mx-auto mt-2">
                        Subtotal ({countItems()} items): <span className="fw-bold">${calcTotal()}</span>
                    </p>
                    <Link
                        to="/checkout"
                        type="submit"
                        className="btn btn-primary mb-3 col-10 mx-auto"
                    >
                        Continue to checkout
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Cart;
