import delivery1 from "./icons/png/delivery1.png"
// import delivery2 from "./icons/png/delivery2.png"
// import delivery3 from "./icons/png/delivery3.png"
// import combine1 from "./icons/png/combine1.png"
// import combine2 from "./icons/png/combine2.png"
// import combine3 from "./icons/png/combine3.png"
import combine4 from "./icons/png/combine4.png"
// import checkout1 from "./icons/png/checkout1.png"
import checkout2 from "./icons/png/checkout2.png"
// import checkout3 from "./icons/png/checkout3.png"
import low_prices from "./icons/png/low_prices.png"

let HomeHowItWorks = () => {
    return (
        <section className="HomeHowItWorks" id="services">
            <div className="container">
                <div className="text-center">
                    {/* <h1 className="home-title mb-5">HOW IT WORKS</h1> */}
                </div>
                <div className="row text-center">
                    <div className="col-md-3 d-flex flex-column align-items-center">
                        <img src={checkout2}  alt="small icon depicting a shopping cart"/>
                        <h4 className="my-3">Place an Order</h4>
                        <p className="text-muted">
                            You sign up on our site to participate in a bulk order for no charge.
                        </p>
                    </div>
                    <div className="col-md-3 d-flex flex-column align-items-center">
                        <img src={combine4}  alt="small icon depicting multiple orders"/>
                        <h4 className="my-3">Combine orders</h4>
                        <p className="text-muted">
                            Our system matches you up with other orders of the same item and combine them.
                        </p>
                    </div>
                    <div className="col-md-3 d-flex flex-column align-items-center">
                        <img src={low_prices} style={{width: "64px"}} alt="small icon depicting prices lowering"/>
                        <h4 className="my-3">Save</h4>
                        <p className="text-muted">
                            You save money by buying in bulk and getting the wholesale price.
                        </p>
                    </div>
                    <div className="col-md-3 d-flex flex-column align-items-center">
                        <img src={delivery1}  alt="small icon depicting a shipping van"/>
                        <h4 className="my-3">Shipped!</h4>
                        <p className="text-muted">
                            When the order is fulfilled, you are charged, and your product is shipped to you!
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeHowItWorks;
