import { useState } from "react";
import CsvFieldDropdown from "./CsvFieldDropdown";
import CSVReader from "./CsvReader";

const AddProductsForm = () => {
    const [showForm, setShowForm] = useState(false);
    const [category, setCategory] = useState("select category");
    const [correspondingFields, setCorrespondingFields] = useState({
        name: "",
        description: "",
        category: "",
        comp_price: "",
        min_qty: "",
        image: "",
    });
    const [vendor, setVendor] = useState("");
    const [csvFields, setCsvFields] = useState([]);
    const [csvData, setCsvData] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        let selectedFields = Object.values(correspondingFields);
        let extraFields = [];
        for (let field of csvFields) {
            if (!selectedFields.includes(field)) {
                extraFields.push(field);
            }
        }

        // console.log("--------------------------------")
        // console.log("fields:", correspondingFields)
        // console.log("extra fields:", extraFields)
        // console.log("--------------------------------")

        let body = {
            vendor: vendor,
            correspondingFields: correspondingFields,
            extraFields: extraFields,
            csvData: csvData,
        };

        const options = {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        };

        let res = await fetch(`${process.env.REACT_APP_API_URL}/products/csv`, options);
        let uploadData = await res.json();
        console.log(uploadData);
    };

    const alertConfirm = () => {
        alert("Products successfully imported!");
    };

    return (
        <div className="NewProductForm w-100 d-flex flex-column align-items-center">
            <div className="col-12 text-start ms-3">
                <div className="col-10 py-2 mb-4 mx-auto">
                    <div className="row justify-content-start px-0">
                        <div className="col-12 d-flex flex-column">
                            <h2 className="text-start">Import CSV</h2>
                            <div className="col-6">
                                <CSVReader
                                    setCsvFields={setCsvFields}
                                    setCsvData={setCsvData}
                                    setShowForm={setShowForm}
                                />
                            </div>
                        </div>
                        {showForm && (
                            <div className="row justify-content-end px-0">
                                <div className="col-12 d-flex justify-content-end align-items-center mt-3">
                                    <button className="btn btn-danger" onClick={() => setShowForm(false)}>
                                        Clear form
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    {showForm ? (
                        <form onSubmit={handleSubmit} className="p-1">
                            <hr />
                            <div className="mb-3 row col-8 mx-auto">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Name of Vendor"
                                name="vendor"
                                value={vendor}
                                onChange={(e) => setVendor(e.target.value)}
                                aria-label="Vendor Name"
                                aria-describedby="basic-addon1"
                            />
                            </div>

                            <hr />
                            <div className="mb-3 row col-8 mx-auto">
                                <div className="col-6 d-flex text-center justify-content-center">
                                    <label htmlFor="productName" className="form-label">
                                        <h1>Name</h1>
                                    </label>
                                </div>
                                <div className="col-6 d-flex text-center justify-content-center">
                                    <CsvFieldDropdown
                                        csvFields={csvFields}
                                        corrFields={correspondingFields}
                                        setCorrFields={setCorrespondingFields}
                                        thisField={"name"}
                                    />
                                </div>
                            </div>
                            <hr />
                            <div className="mb-3 row col-8 mx-auto">
                                <div className="col-6 d-flex text-center justify-content-center">
                                    <label htmlFor="productName" className="form-label">
                                        <h1>Description</h1>
                                    </label>
                                </div>
                                <div className="col-6 d-flex text-center justify-content-center">
                                    <CsvFieldDropdown
                                        csvFields={csvFields}
                                        corrFields={correspondingFields}
                                        setCorrFields={setCorrespondingFields}
                                        thisField={"description"}
                                    />
                                </div>
                            </div>
                            <hr />
                            <div className="mb-3 row col-8 mx-auto">
                                <div className="col-6 d-flex text-center justify-content-center">
                                    <label htmlFor="productName" className="form-label">
                                        <h1>Category</h1>
                                    </label>
                                </div>
                                <div className="col-6 d-flex text-center justify-content-center">
                                    <CsvFieldDropdown
                                        csvFields={csvFields}
                                        corrFields={correspondingFields}
                                        setCorrFields={setCorrespondingFields}
                                        thisField={"category"}
                                    />
                                </div>
                            </div>
                            <hr />
                            <div className="mb-3 row col-8 mx-auto">
                                <div className="col-6 d-flex text-center justify-content-center">
                                    <label htmlFor="productName" className="form-label">
                                        <h1>Vendor Price</h1>
                                    </label>
                                </div>
                                <div className="col-6 d-flex text-center justify-content-center">
                                    <CsvFieldDropdown
                                        csvFields={csvFields}
                                        corrFields={correspondingFields}
                                        setCorrFields={setCorrespondingFields}
                                        thisField={"comp_price"}
                                    />
                                </div>
                            </div>
                            <hr />
                            <div className="mb-3 row col-8 mx-auto">
                                <div className="col-6 d-flex text-center justify-content-center">
                                    <label htmlFor="productName" className="form-label">
                                        <h1>Min Qty</h1>
                                    </label>
                                </div>
                                <div className="col-6 d-flex text-center justify-content-center">
                                    <CsvFieldDropdown
                                        csvFields={csvFields}
                                        corrFields={correspondingFields}
                                        setCorrFields={setCorrespondingFields}
                                        thisField={"min_qty"}
                                    />
                                </div>
                            </div>
                            <hr />
                            <div className="mb-3 row col-8 mx-auto">
                                <div className="col-6 d-flex text-center justify-content-center">
                                    <label htmlFor="productName" className="form-label">
                                        <h1>Image</h1>
                                    </label>
                                </div>
                                <div className="col-6 d-flex text-center justify-content-center">
                                    <CsvFieldDropdown
                                        csvFields={csvFields}
                                        corrFields={correspondingFields}
                                        setCorrFields={setCorrespondingFields}
                                        thisField={"image"}
                                    />
                                </div>
                            </div>
                            <hr className="mb-5" />
                            <div className="">
                                {/* <CsvFieldDropdown
                                        csvFields={csvFields}
                                        corrFields={correspondingFields}
                                        setCorrFields={setCorrespondingFields}
                                        thisField={" "}
                                    /> */}
                            </div>
                            <div className="col-12 d-flex flex-column align-items-center">
                                <div className="form-check mb-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="includeExtras"
                                        checked
                                    />
                                    <label className="form-check-label" for="includeExtras">
                                        Save all remaining fields as metadata
                                    </label>
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-lg"
                                    data-bs-dismiss="modal"
                                    data-bs-target="#newProductModal"
                                    aria-label="Close"
                                    onClick={alertConfirm}
                                >
                                    Submit Product
                                </button>
                            </div>
                        </form>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    );
};
export default AddProductsForm;
