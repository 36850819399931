import { useState } from "react";

const CsvFieldDropdown = ({ csvFields, corrFields, setCorrFields, thisField }) => {

    const [display, setDisplay] = useState("Select Field");

    const setCorrectField = (f) => {
        let newFields = corrFields;
        newFields[thisField] = f;
        setDisplay(f);
        setCorrFields(newFields);
    }



    return (
        <div className="dropdown">
            <button
                className="btn btn-outline-dark btn-lg dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                {display}
            </button>
            <ul className="dropdown-menu">
                {csvFields.map((f) => (
                    <li key={f}>
                        <p
                            className="dropdown-item dd-item-hover mb-0"
                            onClick={() => setCorrectField(f)}
                        >
                            {f}
                        </p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CsvFieldDropdown;
