/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { capsFirstLetter } from "../../../util/functions";
import NewCategoryModal from "./Categories/NewCategoryModal";
import EditCategoryModal from "./Categories/EditCategoryModal";

function CategoriesPanel() {
    let [categories, setCategories] = useState([]);
    let [curCategory, setCurCategory] = useState({});

    const deleteCategory = async (category) => {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/categories/${category}`, {
            method: "DELETE",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        });
        let data = await res.json();
        console.log("data", data);

        if (res.status !== 200) {
            console.log("data", data);
            alert("Failed to delete category");
        }
        setCategories(categories.filter((c) => c.name !== category));
    };

    useEffect(() => {
        const getCategories = async () => {
            let res = await fetch(`${process.env.REACT_APP_API_URL}/categories`);
            let data = await res.json();

            setCategories(data);
        };
        getCategories();
    }, []);

    return (
        <div className="CategoriesPanel col-12 d-flex flex-column align-items-center">
            <div className="col-12 text-start ms-3">
                <h3 className="display-4 col-12 text-center">Categories:</h3>
                <div
                    className="col-lg-6 col-md-8 col-sm-10 d-flex flex-row justify-content-center mx-auto my-3"
                    style={{ marginEnd: "-10px" }}
                >
                    <div className="col-6"></div>
                    <div className="col-6 d-flex justify-content-end">
                        <button
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#newCategoryModal"
                            type="button"
                        >
                            Create new category
                        </button>
                    </div>
                </div>
                {categories.map((c) => (
                    <div
                        key={c.name}
                        className="ap-cat-card col-lg-6 col-md-8 col-sm-10 d-flex flex-row justify-content-center mx-auto my-3"
                        style={{ minWidth: "600px" }}
                    >
                        <div className="col-3 d-flex justify-content-center align-items-center border p-3 bg-light">
                            <img src={c.images[0]} className="img-fluid" alt="no images" />
                        </div>
                        <div className="ap-cat-card-inner col-9 flex-row align-items-center d-flex border ps-3 pe-4">
                            <div className="ap-cat-card-title col-6 fs-3">{capsFirstLetter(c.name)}</div>
                            <button
                                className="col-3 btn btn-primary me-2"
                                data-bs-toggle="modal"
                                data-bs-target="#editCategoryModal"
                                type="button"
                                onClick={() => setCurCategory(c)}
                            >
                                Edit
                            </button>
                            <button className="col-3 btn btn-danger" onClick={() => deleteCategory(c.name)}>
                                Delete
                            </button>
                        </div>
                    </div>
                ))}
                <NewCategoryModal categories={categories} setCategories={setCategories} />
                <EditCategoryModal categories={categories} setCategories={setCategories} category={curCategory} />
            </div>
        </div>
    );
}

export default CategoriesPanel;
