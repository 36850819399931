import { useState, useEffect } from "react";

const CheckoutShippingForm = ( {user, setReload} ) => {
    const [inputs, setInputs] = useState({
        full_name: "",
        phone: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
    });
    const { full_name, phone, address1, address2, city, state, zip } = inputs;

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setInputs({
            ...inputs,
            [name]: value,
        });
        console.log(inputs);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const body = {
            newAddress: inputs
        }

        const res = await fetch(`${process.env.REACT_APP_API_URL}/users/${user.email}/addresses`, {
            method: "PUT",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });


        const data = await res.json();
        setReload(true)
        console.log("Updated user address: ", data);
    }

    return (
        <form className="mx-auto" onSubmit={handleSubmit}>
            <div className="">
                <div className="text-start mb-2">
                    <label htmlFor="name" className="form-label fs-6 fw-bold" style={{ fontWeight: "500px" }}>
                        Full name (first and last)
                    </label>
                    <input
                        type="text"
                        className="form-control form-control-sm fs-6"
                        name="full_name"
                        placeholder=""
                        value={full_name}
                        onChange={handleOnChange}
                        required
                    />
                </div>
                <div className="text-start mb-2">
                    <label htmlFor="phone" className="form-label fs-6 fw-bold" style={{ fontWeight: "500px" }}>
                        Phone Number
                    </label>
                    <input
                        type="text"
                        className="form-control form-control-sm fs-6"
                        name="phone"
                        value={phone}
                        onChange={handleOnChange}
                        placeholder=""
                        required
                    />
                </div>
                <div className="text-start mb-2">
                    <label htmlFor="address" className="form-label fs-6 fw-bold" style={{ fontWeight: "500px" }}>
                        Address
                    </label>
                    <input
                        type="text"
                        className="form-control form-control-sm fs-6 mb-1"
                        name="address1"
                        value={address1}
                        onChange={handleOnChange}
                        placeholder="Street address or P.O. box"
                        required
                    />
                    <input
                        type="text"
                        className="form-control form-control-sm fs-6"
                        name="address2"
                        value={address2}
                        onChange={handleOnChange}
                        placeholder="Apt, suite, unit, building, floor, etc"
                    />
                </div>
                <div className="row justify-content-between mt-1">
                    <div className="text-start mb-2 pe-1 col-5">
                        <label htmlFor="city" className="form-label fs-6 fw-bold" style={{ fontWeight: "500px" }}>
                            City
                        </label>
                        <input
                            type="text"
                            className="form-control form-control-sm fs-6"
                            name="city"
                            placeholder=""
                            value={city}
                            onChange={handleOnChange}
                            required
                        />
                    </div>
                    <div className="text-start mb-2 px-1 col">
                        <label htmlFor="state" className="form-label fs-6 fw-bold" style={{ fontWeight: "500px" }}>
                            State
                        </label>
                        <input
                            type="text"
                            className="form-control form-control-sm fs-6"
                            name="state"
                            value={state}
                            onChange={handleOnChange}
                            placeholder=""
                            required
                        />
                    </div>
                    <div className="text-start mb-2 ps-1 col">
                        <label htmlFor="zip" className="form-label fs-6 fw-bold" style={{ fontWeight: "500px" }}>
                            Zip
                        </label>
                        <input
                            type="text"
                            className="form-control form-control-sm fs-6"
                            name="zip"
                            placeholder=""
                            value={zip}
                            onChange={handleOnChange}
                            required
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-end mt-4">
                    <button className="btn btn-primary" type="submit">
                        Use this address
                    </button>
                </div>
            </div>
        </form>
    );
};

export default CheckoutShippingForm;
