import React, { CSSProperties } from "react";

import { useCSVReader } from "react-papaparse";

const styles = {
    csvReader: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 10,
    },
    browseFile: {
        width: "45%",
    },
    acceptedFile: {
        border: "1px solid #ccc",
        height: 45,
        lineHeight: 2.5,
        paddingLeft: 10,
        width: "80%",
    },
    remove: {
        padding: "0 20px",
    },
    progressBarBackgroundColor: {
        backgroundColor: "blue",
    },
};


const CSVReader = ({ setCsvFields, setCsvData, setShowForm }) => {
    const { CSVReader } = useCSVReader();

    const filterCsvData = (results) => {
        const data = results.data.filter((r) => r.type === "Import Items");

        console.log("---------------------------");
        console.log(data);
        console.log(Object.keys(data[0]));
        console.log("---------------------------");
        setCsvFields(Object.keys(data[0]));
        setCsvData(data);
        setShowForm(true);
    };


    return (
        <CSVReader config={{ header: true }} onUploadAccepted={(results) => filterCsvData(results)}>
            {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
                <>
                    <div style={styles.csvReader}>
                        <button className="btn btn-primary" type="button" {...getRootProps()} style={styles.browseFile}>
                            Browse file
                        </button>
                        <div style={styles.acceptedFile}>{acceptedFile && acceptedFile.name}</div>
                        <button className="btn btn-danger" {...getRemoveFileProps()} style={styles.remove}>
                            Remove
                        </button>
                    </div>
                    <ProgressBar style={styles.progressBarBackgroundColor} />
                </>
            )}
        </CSVReader>
    );
};

export default CSVReader;
