import { Link } from 'react-router-dom';

const CheckoutComplete = () => {
    return (
        <div className="CheckoutComplete mt-5">
            <h1>Payment Complete!</h1>
            <p>Thank you for your purchase.</p>
            <div className="col-3 mx-auto">
                <Link className="btn btn-outline-primary" to="/account" state={{tab: "orders"}} >Click here to view your order</Link>
            </div>
        </div>
    );
}

export default CheckoutComplete;
