import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import NavBar from "./components/Nav/NavBar";
import Footer from "./components/Footer";
import Home from "./components/Home/Home";
import ProductList from "./components/ProductList";
import Product from "./components/Product";
import Cart from "./components/Cart";
import Checkout from "./components/Checkout/Checkout";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import Auth from "./components/Auth";
import AccountPage from "./components/AccountPage";
import VerifyEmail from "./components/VerifyEmail";
import ForgotPassword from "./components/ForgotPassword.js";
import ResetPassword from "./components/ResetPassword.js"

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState } from "react";

import CartContext from "./context/cartContext";
import UserContext from "./context/userContext";

function App() {
    const [cart, setCart] = useState([]);
    const [user, setUser] = useState("")
    const cartValue = { cart, setCart };
    const userValue = { user, setUser };

    return (
        // need to clean up contexts
        <UserContext.Provider value={userValue}>
            <CartContext.Provider value={cartValue}>

                <Router>
                    <div className="App d-flex flex-column min-vh-100 lead">
                        <NavBar />
                        <Routes>
                            <Route exact path="/" element={<Home />} />
                            <Route exact path="/products" element={<ProductList />} />
                            <Route exact path="/products/:id" element={<Product />} />
                            <Route exact path="/cart" element={<Cart />} />
                            <Route exact path="/checkout" element={<Checkout />} />
                            <Route exact path="/signup" element={<SignUp />} />
                            <Route exact path="/login" element={<Login />} />
                            <Route exact path="/auth" element={<Auth />} />
                            <Route exact path="/account" element={<AccountPage />} />
                            <Route exact path="/verify/:ev_str" element={<VerifyEmail />} />
                            <Route exact path="/forgot-password" element={<ForgotPassword />} />
                            <Route exact path="/reset-password/:ev_str" element={<ResetPassword />} />
                            <Route path="/*" element={<Home />} />
                        </Routes>
                        <Footer />
                    </div>
                </Router>
            </CartContext.Provider>
        </UserContext.Provider>
    );
}

export default App;
