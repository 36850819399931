import { useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useCookies } from "react-cookie";

import UserContext from "../../context/userContext";
import CartContext from "../../context/cartContext";

const AccDropdown = () => {
    const navigate = useNavigate();
    const [cookies, removeCookie] = useCookies(["token"]);

    const { user, setUser } = useContext(UserContext);
    const { cart, setCart } = useContext(CartContext);

    const verifyCookie = async () => {
        // console.log("ran verifyCookie");
        if (!cookies.token) {
            // navigate("/login");
            // cookies.updateCookies();
            // console.log("not logged in, no token in cookies.");
        }
        const res = await fetch(`${process.env.REACT_APP_API_URL}/auth`, {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        });

        const data = await res.json();

        const { status, authUser } = data;
        // console.log("authUser: ", authUser)
        setUser(authUser);

        // Check status from post request to auth - if returns false, remove cookie and navigate back to login page
        // if (!status) {
        //     // console.log("status returned false | cookies.token:", cookies.token)
        //     setUsername("");
        //     removeCookie("token")
        //     navigate("/login");
        // }
        return status;
    };

    useEffect(() => {
        verifyCookie();
    }, [user]);

    // Logout function only works when below useEffect... i dont know why
    const logout = async () => {
        await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        });
        console.log("ran logout from AccDropdown");
        removeCookie("token");
        setUser(null);
        setCart(null);
        navigate("/login");
    };

    if (!user || user.length === 0) {
        return (
            <>
                <li className="nav-item">
                    <div className="dropdown-center">
                        <div
                            className="nav-link dropdown-toggle"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            Account
                        </div>
                        <ul className="dropdown-menu">
                            <li>
                                <Link className="dropdown-item" to="/login">
                                    Sign in
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>
            </>
        );
    }

    return (
        <>
            <li className="nav-item d-flex align-items-end">
                <div className="dropdown-center">
                    <div
                        className="nav-link dropdown-toggle d-flex align-items-center"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        fill="currentColor"
                        className=""
                        viewBox="0 0 50 50"
                    >
                        <path d="M25 1C11.767 1 1 11.767 1 25c0 7.091 3.094 13.472 8 17.869v.017l.348.3c.061.053.128.097.19.149a24.496 24.496 0 0 0 3.189 2.279c.085.051.172.099.257.148.557.324 1.126.629 1.71.908l.018.008a23.838 23.838 0 0 0 3.915 1.456l.075.021c.641.175 1.293.322 1.954.443l.185.033a24.17 24.17 0 0 0 1.939.262c.075.007.15.011.224.017.659.055 1.323.09 1.996.09s1.337-.035 1.996-.09c.075-.006.15-.01.224-.017.655-.06 1.301-.15 1.939-.262l.185-.033a23.451 23.451 0 0 0 1.954-.443l.075-.021a23.838 23.838 0 0 0 3.915-1.456l.018-.008a24.261 24.261 0 0 0 1.71-.908c.086-.05.172-.097.257-.148a24.123 24.123 0 0 0 1.487-.968c.124-.087.248-.174.371-.264.456-.334.9-.683 1.331-1.047.062-.052.129-.096.19-.149l.348-.3v-.017c4.906-4.398 8-10.778 8-17.869C49 11.767 38.233 1 25 1zm0 24c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm3 2c6.065 0 11 4.935 11 11v3.958c-.042.035-.086.067-.128.102-.395.321-.8.626-1.214.918-.092.065-.182.132-.274.195-.447.305-.906.591-1.373.862l-.257.148a21.799 21.799 0 0 1-6.871 2.468l-.171.031a22.27 22.27 0 0 1-1.715.225c-.079.007-.159.012-.239.018-.583.045-1.169.075-1.758.075s-1.175-.03-1.758-.077l-.239-.018a21.789 21.789 0 0 1-1.886-.256 22.013 22.013 0 0 1-5.212-1.626l-.161-.073a21.799 21.799 0 0 1-1.755-.917c-.467-.27-.926-.557-1.373-.862-.093-.063-.183-.13-.274-.195a21.826 21.826 0 0 1-1.214-.918c-.042-.034-.086-.067-.128-.102V38c0-6.065 4.935-11 11-11h6zm13 13.076V38c0-6.271-4.464-11.519-10.38-12.735A9.996 9.996 0 0 0 35 17c0-5.514-4.486-10-10-10s-10 4.486-10 10a9.996 9.996 0 0 0 4.38 8.265C13.464 26.481 9 31.729 9 38v2.076C5.284 36.135 3 30.831 3 25 3 12.869 12.869 3 25 3s22 9.869 22 22c0 5.831-2.284 11.135-6 15.076z"></path>
                    </svg>
                        &nbsp;Hello, {user}
                    </div>
                    <ul className="dropdown-menu dropdown-menu-start text-center me-5"> {/* Since dropdown is centered based on arrow, me-5 pushes it back to center of dropdown */}
                        <li>
                            <Link className="dropdown-item" to="/account" state={{tab: "home"}}>
                                Your Account
                            </Link>
                        </li>
                        <li>
                            <Link className="dropdown-item"  to="/account" state={{tab: "orders"}}>
                                Your Orders
                            </Link>
                        </li>
                        <li>
                            <Link className="dropdown-item" to="/account" state={{tab: "lists"}} >
                                Your Lists
                            </Link>
                        </li>
                        <li>
                            <Link className="dropdown-item" to="/account" state={{tab: "settings"}} >
                                Settings
                            </Link>
                        </li>
                        <li>
                            <Link className="dropdown-item" onClick={logout} to="/login">
                                Log Out
                            </Link>
                        </li>
                    </ul>
                </div>
            </li>
        </>
    );
};

export default AccDropdown;
