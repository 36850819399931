import { useState } from "react";

import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import AddProductsForm from "./AddProductsForm";

function AddProductsModal(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <>
            <div
                className="modal fade"
                id="addProductsModal"
                tabIndex="-1"
                aria-labelledby="addProductsModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-fullscreen-xl-down modal-xl">
                    <div className="modal-content">
                        <div className="modal-header ">
                            <h1 className="modal-title fs-3 ms-2" id="addProductsModalLabel">
                                Add Products from CSV
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <AddProductsForm
                                    products={props.products}
                                    setProducts={props.setProducts}
                                    categories={props.categories}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
            >
                <Typography onMouseLeave={handleClose} sx={{ p: 2 }}>
                    Successfully added new product!
                </Typography>
            </Popover>
        </>
    );
}

export default AddProductsModal;
