import { useEffect } from "react";

import CheckoutPaymentForm from "./CheckoutPaymentForm";

const CheckoutPayment = ({ clientSecret, customerClientSecret, setPageShowing, cart, user, orderInfo }) => {
    const getCartTotal = () => {
        let t = 0;
        cart.forEach((item) => {
            t += item.product_price * item.qty;
        });
        return t * 100;
    };

    const countItems = () => {
        let count = 0;
        cart.forEach((item) => {
            count += parseInt(item.qty);
        });
        return count;
    };

    useEffect(() => {
        // const getStripeCustomer = async () => {
        //     if (user.stripe_id) {
        //         const res = await fetch(`${process.env.REACT_APP_API_URL}/get-customer/${user.stripe_id}`)
        //         const data = await res.json();
        //         console.log("getStripeCustomer:", data);
        //     }
        //     else {
        //         console.log("no stripe_id found in user object");
        //     }
        // };
        // getStripeCustomer();

        // ---------------- SHOULD RE-IMPLEMENT AT SOME POINT PROBABLY ----------------
        // const updatePaymentIntent = async () => {
        //     fetch(`${process.env.REACT_APP_API_URL}/create-payment-intent`, {
        //         method: "POST",
        //         mode: "cors",
        //         credentials: "include",
        //         headers: { "Content-Type": "application/json" },
        //         body: JSON.stringify({ pi_s: clientSecret, items: cart, total: getCartTotal(cart), customer_id: user.stripe_id }),
        //     })
        //         .then((res) => res.json())
        //         .then((data) => console.log("updated PaymentIntent:", data));
        // };
        // updatePaymentIntent();
    }, []);

    if (cart.length === 0) {
        // Should add loading element here
        return <></>;
    }

    return (
        <div className="CheckoutPayment">
            <div className="col-12 col-lg-11 col-xl-10 d-flex justify-content-start mx-auto">
                <div className="row col-4 col-md-3 col-xl-2 ms-2 mb-2">
                    <button
                        to="/cart"
                        className="btn btn-outline-primary btn-sm w-50"
                        onClick={() => setPageShowing("shipping-info")}
                    >
                        Back
                    </button>
                </div>
            </div>
            <div
                className="col-12 col-lg-11 col-xl-10 d-flex justify-content-between mx-auto"
                style={{ minWidth: "860px" }}
            >
                {/******* Main Box col-8 ********/}
                <div className="col-8 mx-auto pt-3 ms-2 border border-dark rounded">
                    <div className="col-11 mx-auto">
                        <div className="col-12 d-flex justify-content-between">
                            <h1 className="text-start display-6 my-3">Select Payment Option</h1>
                            <div className="my-auto">
                                <button
                                    className="btn btn-sm btn-outline-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#checkoutShippingModal"
                                    type="button"
                                >
                                    Add Payment Option
                                </button>
                            </div>
                        </div>
                        <hr className="mx-auto mb-3" />
                    </div>
                    <div className="">
                        <div className="my-auto">
                            <CheckoutPaymentForm setPageShowing={setPageShowing} cart={cart} username={user.username} />
                        </div>
                    </div>
                </div>

                {/******* Info Box col-3 ********/}
                <div className="col-3 d-flex flex-column justify-content-between border border-dark rounded me-3 pt-2" style={{ minHeight: "10rem", maxHeight: "27.5rem" }}>
                    <div className="col-10 mx-auto">
                        <h3 className="text-start">Shipping to:</h3>
                            <p className="text-start mt-2 ms-2">
                                <span className="fw-bold">{orderInfo.shippingInfo.full_name}</span><br />
                                {orderInfo.shippingInfo.address1},&nbsp;
                                {orderInfo.shippingInfo.city} <br />
                                {orderInfo.shippingInfo.country},&nbsp;
                                {orderInfo.shippingInfo.state}&nbsp;
                                {orderInfo.shippingInfo.zip} <br />
                            </p>
                        <hr />
                        <h3 className="text-start">Cart:</h3>
                        <p className="text-start mt-2 ms-2">
                            Subtotal ({countItems()} items):{" "} <br />
                            <span className="fw-bold">${(getCartTotal() / 100).toFixed(2)}</span>
                        </p>
                        <hr />
                    </div>

                    <button
                        form='payment-form'
                        type="submit"
                        className="btn btn-primary mb-3 px-4 mx-auto"
                    >
                        Submit Order
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CheckoutPayment;
