import { useState } from "react";

import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import CheckoutShippingForm from "./CheckoutShippingForm";

function CheckoutShippingModal({user, setReload}) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <>
            <div
                className="modal fade mt-4"
                id="checkoutShippingModal"
                tabIndex="-1"
                aria-labelledby="checkoutShippingModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content container-fluid">
                        <div className="modal-header col-11 mx-auto px-0">
                            <h1 className="modal-title fs-3" id="checkoutShippingModalLabel">
                                New Delivery Address
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body col-11 mx-auto px-0">
                            <CheckoutShippingForm user={user} setReload={setReload}/>
                        </div>
                    </div>
                </div>
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
            >
                <Typography onMouseLeave={handleClose} sx={{ p: 2 }}>
                    Successfully added new product!
                </Typography>
            </Popover>
        </>
    );
}

export default CheckoutShippingModal;
