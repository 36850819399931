import { useContext, useState } from "react";
import cartContext from "./cartContext";
import userContext from "./userContext";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

const CartUpdater = (props) => {
    const { cart, setCart } = useContext(cartContext);
    const { user, setUser } = useContext(userContext);
    let btnStyle = props.btnStyle

    const [anchorEl, setAnchorEl] = useState(null);

    const product = props.item.product

    const item = {
        product_id: product.product_id,
        product_name: product.name,
        product_price: product.price.whole + (product.price.fraction / 100),
        min_qty: product.min_qty,
        qty: props.item.qty,
        image: props.item.image
    }

    // const updateDbCart = async (newCart) => {
    //     // update cart from db if window was closed and reloaded.



    //     console.log("ran updateDbCart()")

    //     const body = {
    //         cart: newCart
    //     }
    //     const res = await fetch(`${process.env.REACT_APP_API_URL}/cart/${user}`, {
    //         method: "PUT",
    //         mode: "cors",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         credentials: "include",
    //         body: JSON.stringify(body),
    //     });
    // }

    const updateCart = async () => {
        // let itemExists = cart.findIndex(i => i.product_id === item.product_id)
        // let arr = cart
        // if (itemExists > -1){
        //     arr[itemExists].qty++
        // }
        // else {
        //     arr = ([...arr, item])
        // }

        // setCart(arr)
        // updateDbCart(arr)

        let body = {
            item: item,
        }

        const res = await fetch(`${process.env.REACT_APP_API_URL}/cart/${user}/add`, {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(body),
        });
    }

    const handleClick = (e) => {
        // To prevent "add to cart" button from redirecting
        e.stopPropagation();
        e.preventDefault();

        if(user !== undefined) {
            updateCart()
        }
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // create function to have popover disappear on timer

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <>
            <button className={"btn btn-primary " + btnStyle} aria-describedby={id} onClick={handleClick}>
                Add to Cart
            </button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
            >
                <Typography onMouseLeave={handleClose} sx={{ p: 2 }}>
                    {user === undefined ? "Please sign in to add items to cart" : "Added Item to cart"}
                </Typography>
            </Popover>
        </>
    );
};

export default CartUpdater;
