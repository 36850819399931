import { useEffect, useState } from "react";
import OrderBar from "../../UP_OrderBar";

function UserOrdersPanel(props) {
    let [orders, setOrders] = useState([]);
    let [userOrders, setUserOrders] = useState([]);

    const user = props.user;
    console.log("user:", user);

    useEffect(() => {
        const getOrders = async () => {
            let res = await fetch(`${process.env.REACT_APP_API_URL}/orders`)
            let orders = await res.json();

            setOrders(orders);
            getUserOrders();
        }
        const getUserOrders = async () => {
            if (user) {
                const prodRes = await fetch(`${process.env.REACT_APP_API_URL}/products`);
                const products = await prodRes.json();

                let arr = user.orders;

                for (let i = 0; i < arr.length; i++) {
                    arr[i]["p"] = products.find((p) => p.product_id === arr[i].product_id);
                }

                console.log("arr:", arr);
                setUserOrders(arr);
            } else {
                console.log("no user ");
            }
        };
        getOrders();
    }, [user]);

    if (userOrders.length === 0 || !userOrders) {
        return <></>;
    }

    return (
        <div className="UserOrdersPanel d-flex flex-column align-items-center">
            <div className="col-12 text-start ms-3">
                <h3 className="display-4 col-12">Your orders:</h3>
                {userOrders.map((order) => (
                    <div key={order.p.product_id} className="col-12 d-flex flex-row align-items-center mx-3 border">
                        {/* <div className="col-8 flex-row align-items-center d-flex"> */}
                            <div className="col-2 d-flex justify-content-start">
                                <img src={order.p.image} alt="" className="img-fluid" />
                            </div>
                            <div className="col-2 d-flex justify-content-center fw-bold">{order.p.name}</div>
                            <div className="col-3 d-flex justify-content-center">You Ordered: {order.qty}</div>
                            {/* <div className="col-2">Min Qty to Order: {order.p.min_qty}</div> */}
                            <div className="col-3">
                                {/* <OrderBar min_qty={order.p.min_qty} qty={order.qty}/> */}
                                <OrderBar product={order.p} orders={orders} barStyle={{height: "1rem", width: "100%", marginLeft: "0rem"}}/>

                                {/* <NewOrderBar product={order.p} order={order} barStyle={{height: "30px"}}/> */}

                            </div>
                        {/* </div> */}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default UserOrdersPanel;
