import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import CheckoutShippingModal from "./CheckoutShippingModal";

const CheckoutShipping = ({ setPageShowing, cart, user, verifyCookie, orderInfo, setOrderInfo }) => {
    const [shippingInfo, setShippingInfo] = useState([]);
    const [reload, setReload] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(0);

    const calcTotal = () => {
        let total = 0;
        cart.forEach((item) => {
            total += item.product_price * item.qty;
        });
        return total.toFixed(2);
    };

    const countItems = () => {
        let count = 0;
        cart.forEach((item) => {
            count += parseInt(item.qty);
        });
        return count;
    };

    const handleNextPage = async () => {
        await setOrderInfo({
            ...orderInfo,
            shippingInfo: user.addresses[selectedAddress],
        });
        setPageShowing("payment-info")
    }

    useEffect(() => {
        if (reload === true) {
            verifyCookie();
            setReload(false);
        }
    }, [cart, selectedAddress]);

    if (cart.length === 0) {
        // Should add loading element here
        return <></>;
    }

    return (
        <div className="CheckoutShipping">
            <div className="col-12 col-lg-11 col-xl-10 d-flex justify-content-start mx-auto">
                <div className="row col-4 col-md-3 col-xl-2 ms-2 mb-2">
                    <Link
                        to="/cart"
                        className="btn btn-outline-primary btn-sm w-50"
                        onClick={() => setPageShowing("cart")}
                    >
                        Back
                    </Link>
                </div>
            </div>
            <div
                className="col-12 col-lg-11 col-xl-10 d-flex justify-content-between mx-auto"
                style={{ minWidth: "860px" }}
            >
                {/******* Main Box col-8 ********/}
                <div className="col-8 mx-auto pt-3 ms-2 border border-dark rounded">
                    <div className="col-11 mx-auto">
                        <div className="col-12 d-flex justify-content-between">
                            <h1 className="text-start display-6 my-3">Select a delivery address</h1>
                            <div className="my-auto">
                                <button
                                    className="btn btn-sm btn-outline-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#checkoutShippingModal"
                                    type="button"
                                >
                                    Add Delivery Address
                                </button>
                            </div>
                        </div>
                        <hr className="mx-auto mb-3" />
                    </div>
                    <div className="">
                        {user.addresses.map((address, i) => (
                            <div className="" key={address.full_name}>
                                <div
                                    className={
                                        "col-11 mx-auto mt-2 mb-4 ps-3 py-2 d-flex flex-row border rounded align-items-center" +
                                        (selectedAddress === i ? " border-primary border-2" : " border-dark")
                                    }
                                >
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name={"addressSelect" + i}
                                            checked={selectedAddress === i ? true : false}
                                            id={"addressSelect" + i}
                                            onClick={() => setSelectedAddress(i)}
                                        />
                                    </div>
                                    <p
                                        className="text-start text-dark fs-4 lead mb-0 col-6"
                                        style={{ fontWeight: "400" }}
                                    >
                                        {address.full_name}
                                    </p>
                                    <p
                                        className="text-start text-dark fs-5 lead mb-0 col-5"
                                        style={{ fontWeight: "400" }}
                                    >
                                        {selectedAddress === i ? address.address1 : <></>}
                                    </p>
                                </div>
                            </div>
                        ))}
                        <div className="col-11 mx-auto d-flex flex-row justify-content-center mb-3"></div>
                    </div>
                </div>

                {/******* Info Box col-3 ********/}
                <div
                    className="col-3 d-flex flex-column justify-content-between border border-dark rounded me-3"
                    style={{ height: "10rem" }}
                >
                    <p className="col-10 text-start mx-auto mt-2">
                        Subtotal ({countItems()} items): <span className="fw-bold">${calcTotal()}</span>
                    </p>
                    <button
                        onClick={() => handleNextPage()}
                        type="submit"
                        className="btn btn-primary mb-3 col-10 mx-auto"
                    >
                        Continue to payment
                    </button>
                </div>
            </div>
            <CheckoutShippingModal user={user} setShippingInfo={setShippingInfo} setReload={setReload} />
        </div>
    );
};

export default CheckoutShipping;
